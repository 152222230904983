import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import ScreensCarousel from '../../../../../components/case-studies/ScreensCarousel'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Reveal from '../../../../../components/reveal'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Vermont Matrix'}
          description={'Prípadová štúdia'}
          text={'Vytvorili sme profesionálny systém pre predajne, slúžiaci na kompletný management ľudských zdrojov, prepojený s CRM systémom. Prehľadné a maximálne intuitívne UI je ušité na mieru pre klienta a jeho zamestnancov, ktorí systém využívajú na stovkách svojich pobočiek vo svete.'}
          img={data.landingImage}
          pageMode={false}
          className={'right-mode'}
          noShadow={true}
          what_we_done={[
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (Javascript)', link: '/nase-prace/webstranky/crm/vermont-matrix-ui' },
          ]} />

        <AboutProject
          text={'Spolupráca s Vermont Matrix prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <div className="container pt-5">
          <Img fluid={data.ipad.childImageSharp.fluid} alt={'iPad'} className={'ipad-mockup'} />
        </div>

        <ScreensCarousel
          blueBackground={true}
          items={data.screens.nodes}
        />
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="vermont-matrix" />
          </div>
        </Reveal>

        <MobileCarousel items={data.mobileGallery.nodes} />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.vermont.sk" target='_blank' className='link-secondary'>www.vermont.sk</a>
        </div>


        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Cario'}
          nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/cario/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/vermont_matrix_ui/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/vermondMatrix/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    ipad: file(relativePath: { eq: "case-studies/vermondMatrix/ipad.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/vermondMatrix/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/vermondMatrix/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    screens: allFile(filter: { relativePath: {regex: "/(case-studies/vermondMatrix/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1900) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
